// tokens for bifrost chain
import { compareLowerStr } from '@bifrost-platform/bifront-sdk-react-wallet';
import IS_TESTNET from 'lib/devEnv/envIsTestnet';
import Token from 'types/Token';
import MAINNET_TOKENS, {
  MAINNET_TOKEN_BFC,
  MAINNET_TOKEN_WBFC,
  MAINNET_TOKEN_BIFI,
  MAINNET_TOKEN_ETH,
  MAINNET_TOKEN_BNB,
  MAINNET_TOKEN_MATIC,
  MAINNET_TOKEN_DAI,
  MAINNET_TOKEN_USDC,
  MAINNET_TOKEN_USDT,
  MAINNET_TOKEN_BUSD,
  MAINNET_TOKEN_P2D,
  MAINNET_TOKEN_BTCUSD,
} from './mainnetTokens';
import TESTNET_TOKENS, {
  TESTNET_TOKEN_BFC,
  TESTNET_TOKEN_WBFC,
  TESTNET_TOKEN_BIFI,
  TESTNET_TOKEN_ETH,
  TESTNET_TOKEN_BNB,
  TESTNET_TOKEN_MATIC,
  TESTNET_TOKEN_DAI,
  TESTNET_TOKEN_USDC,
  TESTNET_TOKEN_USDT,
  TESTNET_TOKEN_BUSD,
  TESTNET_TOKEN_P2D,
  TESTNET_TOKEN_BTCUSD,
} from './testnetTokens';
import { TOKEN_SYMBOL_BFC, TOKEN_SYMBOL_WBFC } from './tokenSymbols';

export const TOKEN_BFC: Token = IS_TESTNET
  ? TESTNET_TOKEN_BFC
  : MAINNET_TOKEN_BFC;
export const TOKEN_WBFC: Token = IS_TESTNET
  ? TESTNET_TOKEN_WBFC
  : MAINNET_TOKEN_WBFC;
export const TOKEN_BIFI: Token = IS_TESTNET
  ? TESTNET_TOKEN_BIFI
  : MAINNET_TOKEN_BIFI;
export const TOKEN_ETH: Token = IS_TESTNET
  ? TESTNET_TOKEN_ETH
  : MAINNET_TOKEN_ETH;
export const TOKEN_BNB: Token = IS_TESTNET
  ? TESTNET_TOKEN_BNB
  : MAINNET_TOKEN_BNB;
export const TOKEN_MATIC: Token = IS_TESTNET
  ? TESTNET_TOKEN_MATIC
  : MAINNET_TOKEN_MATIC;
export const TOKEN_USDC: Token = IS_TESTNET
  ? TESTNET_TOKEN_USDC
  : MAINNET_TOKEN_USDC;
export const TOKEN_USDT: Token = IS_TESTNET
  ? TESTNET_TOKEN_USDT
  : MAINNET_TOKEN_USDT;
export const TOKEN_DAI: Token = IS_TESTNET
  ? TESTNET_TOKEN_DAI
  : MAINNET_TOKEN_DAI;
export const TOKEN_BUSD: Token = IS_TESTNET
  ? TESTNET_TOKEN_BUSD
  : MAINNET_TOKEN_BUSD;
export const TOKEN_P2D: Token = IS_TESTNET
  ? TESTNET_TOKEN_P2D
  : MAINNET_TOKEN_P2D;
export const TOKEN_BTCUSD: Token = IS_TESTNET
  ? TESTNET_TOKEN_BTCUSD
  : MAINNET_TOKEN_BTCUSD;

const TOKENS = IS_TESTNET ? TESTNET_TOKENS : MAINNET_TOKENS;

export const BFC_TOKENS: Token[] = TOKENS.filter(
  (token) =>
    compareLowerStr(token.symbol, TOKEN_SYMBOL_BFC) ||
    compareLowerStr(token.symbol, TOKEN_SYMBOL_WBFC)
);
export const BFC_TOKEN_SYMBOLS: string[] = BFC_TOKENS.map(
  (token) => token.symbol
);

export default TOKENS;
