import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import IS_TESTNET, { setIsTestnet } from 'lib/devEnv/envIsTestnet';

const useCheckTestnetProperty = () => {
  const router = useRouter();
  const { pathname, query, locale } = router;

  const checkTestnetProperty = useCallback(async () => {
    if (IS_TESTNET) {
      return;
    }

    const testnetQuery = query['testnet'];
    const testnetProperty =
      testnetQuery && typeof testnetQuery === 'string'
        ? testnetQuery
        : testnetQuery?.[0];

    if (testnetProperty === 'true') {
      await router.replace({ pathname, query }, pathname, { locale });
      setIsTestnet(true);
    }
  }, [locale, pathname, query, router]);

  useEffect(() => {
    checkTestnetProperty();
  }, [checkTestnetProperty]);

  return checkTestnetProperty;
};

export default useCheckTestnetProperty;
