import Navigation from 'types/Navigation';

const NAVIGATIONS: Navigation[] = [
  {
    title: 'header.navigation.swap',
    path: '/swap',
  },
  {
    title: 'header.navigation.pool',
    path: '/pool',
  },
  {
    title: 'header.navigation.lpReward',
    path: '/lpReward',
  },
  {
    title: 'header.navigation.stats',
    path: '/stats',
  },
];

export default NAVIGATIONS;
