export const TOKEN_NAME_BFC = 'Bifrost';
export const TOKEN_NAME_WBFC = 'Bifrost';
export const TOKEN_NAME_BIFI = 'BiFi';
export const TOKEN_NAME_ETH = 'Ethereum';
export const TOKEN_NAME_BNB = 'BNB';
export const TOKEN_NAME_MATIC = 'Polygon';
export const TOKEN_NAME_DAI = 'DAI Stablecoin';
export const TOKEN_NAME_USDC = 'USD Coin';
export const TOKEN_NAME_USDT = 'Tether USD';
export const TOKEN_NAME_BUSD = 'BUSD';
export const TOKEN_NAME_P2D = 'P2D';
export const TOKEN_NAME_WBTC = 'WBTC';
export const TOKEN_NAME_BTCB = 'BTCB';
export const TOKEN_NAME_BTCUSD = 'BTCUSD';

const TOKEN_NAMES = [
  TOKEN_NAME_BFC,
  TOKEN_NAME_WBFC,
  TOKEN_NAME_BIFI,
  TOKEN_NAME_ETH,
  TOKEN_NAME_BNB,
  TOKEN_NAME_MATIC,
  TOKEN_NAME_DAI,
  TOKEN_NAME_USDC,
  TOKEN_NAME_USDT,
  TOKEN_NAME_BUSD,
  TOKEN_NAME_P2D,
  TOKEN_NAME_WBTC,
  TOKEN_NAME_BTCB,
  TOKEN_NAME_BTCUSD,
];

export default TOKEN_NAMES;
