import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useCallback, useMemo } from 'react';
import EmojiPartyPopper from 'components/EmojiPartyPopper';
import { Props as DialogProps } from '.';
import DialogConfirm from './DialogConfirm';

export type Props = DialogProps & {};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;
const Icon = styled.div`
  padding: 20px;
  font-size: 80px;
`;
const Text = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
`;

const DialogLpStakeCta: React.FC<Props> = ({ open, onClose: handleClose }) => {
  const { t } = useTranslation('dialog');

  const router = useRouter();

  const title = useMemo(() => t('lpStakeCta.title'), [t]);
  const content = useMemo(
    () => (
      <Container>
        <Icon>
          <EmojiPartyPopper />
        </Icon>
        <Text>
          {(t('lpStakeCta.content') as string)
            .split('\n')
            .map((line, lineIndex) => (
              <div key={lineIndex}>{line}</div>
            ))}
        </Text>
      </Container>
    ),
    [t]
  );

  const handleConfirm = useCallback(() => {
    router.push('/lpReward');
    handleClose?.({}, 'flowFinish');
  }, [handleClose, router]);

  const confirmProps = useMemo(
    () => ({ children: t('lpStakeCta.action'), onClick: handleConfirm }),
    [handleConfirm, t]
  );

  return (
    <DialogConfirm
      open={open}
      width={360}
      title={title}
      content={content}
      confirmProps={confirmProps}
      onClose={handleClose}
    />
  );
};

export default DialogLpStakeCta;
