import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import checkIsAvailablePathname from 'lib/devEnv/checkIsAvailablePathname';

const useCheckAvailablePathname = () => {
  const router = useRouter();
  const { pathname, query, locale } = router;

  const checkAvailablePathname = useCallback(async () => {
    if (!checkIsAvailablePathname(pathname)) {
      await router.replace({ pathname: '/', query }, '/', { locale });
    }
  }, [locale, pathname, query, router]);

  useEffect(() => {
    checkAvailablePathname();
  }, [checkAvailablePathname]);

  return checkAvailablePathname;
};

export default useCheckAvailablePathname;
