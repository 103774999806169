import { isNormalPositive } from '@bifrost-platform/bifront-sdk-react-wallet';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useCallback, useMemo } from 'react';
import TokenPair from 'components/TokenPair';
import Column from 'components/column';
import { ActionBtn } from 'components/styled/btn';
import LP_REWARD_POOLS from 'configs/lpRewardPools';
import { HOUR_BY_MILLISECONDS } from 'configs/time';
import useLpRewardBalanceAmounts from 'hooks/lpReward/useLpRewardBalanceAmounts';
import LpRewardPool from 'types/LpRewardPool';
import { Props as DialogProps } from '..';
import DialogConfirm from '../DialogConfirm';

export type Props = DialogProps & {};

const Root = styled(Column)`
  gap: 24px;
`;
const DescriptionContainer = styled(Column)`
  gap: 16px;
  align-items: center;
  text-align: center;
`;
const IconContainer = styled.div`
  padding: 24px 0px;
  padding-left: 16px;
`;
const Description = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
`;
const ContentContainer = styled(Column)`
  gap: 40px;
`;
const InstructionContainer = styled(Column)`
  gap: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;
const InstructionTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;
const InstructionItemContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
`;
const InstructionItemTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;
const InstructionItemContent = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;
const TipContainer = styled(Column)`
  gap: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: ${({ theme }) => theme.color.label};
`;
const TipTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: ${({ theme }) => theme.color.point};
`;
const Divider = styled.div`
  padding: 4px 0px;
  &:after {
    content: '';
    display: block;
    border-top: 1px solid ${({ theme }) => theme.color.outline};
  }
`;
const DontShowAgainBtn = styled(ActionBtn)`
  min-width: 240px;
  color: ${({ theme }) => theme.color.black1A};
  background-color: ${({ theme }) => theme.color.greyCC};
`;

const LP_REWARD_POOL: LpRewardPool | undefined = LP_REWARD_POOLS[0];
const { tokens: LP_REWARD_POOL_TOKENS, address: LP_REWARD_POOL_ADDRESS } =
  LP_REWARD_POOL ?? { tokens: [], address: '' };
const LP_REWARD_POOL_TOKEN_SYMBOLS = LP_REWARD_POOL_TOKENS.map(
  (token) => token.symbol
);
const LP_REWARD_POOL_SYMBOLS_TITLE = LP_REWARD_POOL_TOKEN_SYMBOLS.join('/');

const DialogEventNoticeLpReward: React.FC<Props> = ({
  open,
  onClose: handleClose,
}) => {
  const { t } = useTranslation('dialog');

  const router = useRouter();
  const { pathname } = router;

  const balanceAmounts = useLpRewardBalanceAmounts(
    LP_REWARD_POOLS,
    false,
    HOUR_BY_MILLISECONDS
  );

  const hasLp = useMemo(
    () => isNormalPositive(balanceAmounts[0], true),
    [balanceAmounts]
  );
  const routerTarget = useMemo(
    () =>
      hasLp
        ? '/lpReward'
        : `/pool/${LP_REWARD_POOL_ADDRESS}/add-stable-liquidity`,
    [hasLp]
  );
  const isOpen = useMemo(
    () => !!LP_REWARD_POOL && pathname !== '/lpReward' && open,
    [open, pathname]
  );

  const handleDontShowAgain = useCallback(() => {
    handleClose?.({}, 'flowFinish');
  }, [handleClose]);
  const handleConfirm = useCallback(() => {
    router.push(routerTarget);
    handleClose?.({}, 'flowFinish');
  }, [handleClose, router, routerTarget]);

  const title = useMemo(() => t('eventNotice.lpReward.title'), [t]);
  const content = useMemo(
    () => (
      <Root>
        <DescriptionContainer>
          <IconContainer>
            <TokenPair
              isShowTitle={false}
              size={64}
              gap={-12}
              tokens={LP_REWARD_POOL_TOKENS}
            />
          </IconContainer>
          <Description>
            {(
              t('eventNotice.lpReward.body.description', [
                LP_REWARD_POOL_SYMBOLS_TITLE,
              ]) as string
            )
              .split('\n')
              .map((line, lineIndex) => (
                <div key={lineIndex}>{line}</div>
              ))}
          </Description>
        </DescriptionContainer>
        <ContentContainer>
          <Divider />
          <InstructionContainer>
            <InstructionTitle>
              {t('eventNotice.lpReward.body.instructions.title')}
            </InstructionTitle>
            <InstructionItemContainer>
              {[0, 1, 2].map((value, index) => (
                <>
                  <InstructionItemTitle key={`title-${index}`}>
                    {index + 1}.{' '}
                    {t(
                      `eventNotice.lpReward.body.instructions.titles.${index}`
                    )}
                  </InstructionItemTitle>
                  <InstructionItemContent key={`content-${index}`}>
                    {t(
                      `eventNotice.lpReward.body.instructions.contents.${index}`
                    )}
                  </InstructionItemContent>
                </>
              ))}
            </InstructionItemContainer>
          </InstructionContainer>
          <Divider />
          <TipContainer>
            <TipTitle>{t('eventNotice.lpReward.body.tip.title')}</TipTitle>
            <div>{t('eventNotice.lpReward.body.tip.description')}</div>
          </TipContainer>
        </ContentContainer>
      </Root>
    ),
    [t]
  );
  const subconfirmContents = useMemo(
    () => (
      <DontShowAgainBtn onClick={handleDontShowAgain}>
        {t('eventNotice.lpReward.dontShowAgain')}
      </DontShowAgainBtn>
    ),
    [t, handleDontShowAgain]
  );
  const confirmProps = useMemo(
    () => ({
      children: t('eventNotice.lpReward.confirm'),
      onClick: handleConfirm,
    }),
    [t, handleConfirm]
  );

  return (
    <DialogConfirm
      open={isOpen}
      width={640}
      title={title}
      content={content}
      subconfirmContents={subconfirmContents}
      confirmProps={confirmProps}
      onClose={handleClose}
    />
  );
};

export default DialogEventNoticeLpReward;
