import DEFAULT_BLOCK_TERM from 'configs/defaultValue/defaultBlock';
import {
  TESTNET_TOKEN_BFC,
  TESTNET_TOKEN_BNB,
  TESTNET_TOKEN_ETH,
  TESTNET_TOKEN_MATIC,
} from 'configs/tokens/testnetTokens';
import Chain from 'types/Chain';
import {
  TESTNET_CHAIN_API_ID_BIFROST,
  TESTNET_CHAIN_API_ID_ETHEREUM,
  TESTNET_CHAIN_API_ID_BASE_SEPOLIA,
  TESTNET_CHAIN_API_ID_ARBITRUM_SEPOLIA,
  TESTNET_CHAIN_API_ID_BSC,
  TESTNET_CHAIN_API_ID_POLYGON,
  TESTNET_CHAIN_API_ID_BASE,
  TESTNET_CHAIN_API_ID_ARBITRUM,
} from './testnet/chainApiId';
import {
  TESTNET_CHAIN_EXPLORER_URL_BIFROST,
  TESTNET_CHAIN_EXPLORER_URL_ETHEREUM,
  TESTNET_CHAIN_EXPLORER_URL_BASE_SEPOLIA,
  TESTNET_CHAIN_EXPLORER_URL_ARBITRUM_SEPOLIA,
  TESTNET_CHAIN_EXPLORER_URL_BSC,
  TESTNET_CHAIN_EXPLORER_URL_POLYGON,
  TESTNET_CHAIN_EXPLORER_URL_BASE,
  TESTNET_CHAIN_EXPLORER_URL_ARBITRUM,
} from './testnet/chainExplorerUrl';
import {
  TESTNET_CHAIN_ID_BIFROST,
  TESTNET_CHAIN_ID_ETHEREUM,
  TESTNET_CHAIN_ID_BASE_SEPOLIA,
  TESTNET_CHAIN_ID_ARBITRUM_SEPOLIA,
  TESTNET_CHAIN_ID_BSC,
  TESTNET_CHAIN_ID_POLYGON,
  TESTNET_CHAIN_ID_BASE,
  TESTNET_CHAIN_ID_ARBITRUM,
} from './testnet/chainId';
import {
  TESTNET_CHAIN_NAME_BIFROST,
  TESTNET_CHAIN_NAME_ETHEREUM,
  TESTNET_CHAIN_NAME_BASE_SEPOLIA,
  TESTNET_CHAIN_NAME_ARBITRUM_SEPOLIA,
  TESTNET_CHAIN_NAME_BSC,
  TESTNET_CHAIN_NAME_POLYGON,
  TESTNET_CHAIN_NAME_BASE,
  TESTNET_CHAIN_NAME_ARBITRUM,
} from './testnet/chainName';
import {
  TESTNET_CHAIN_RPC_URLS_BIFROST,
  TESTNET_CHAIN_RPC_URLS_ETHEREUM,
  TESTNET_CHAIN_RPC_URLS_BASE_SEPOLIA,
  TESTNET_CHAIN_RPC_URLS_ARBITRUM_SEPOLIA,
  TESTNET_CHAIN_RPC_URLS_BSC,
  TESTNET_CHAIN_RPC_URLS_POLYGON,
  TESTNET_CHAIN_RPC_URLS_BASE,
  TESTNET_CHAIN_RPC_URLS_ARBITRUM,
} from './testnet/chainRpcUrls';

// chain
export const TESTNET_CHAIN_BIFROST: Chain = {
  apiId: TESTNET_CHAIN_API_ID_BIFROST,
  id: TESTNET_CHAIN_ID_BIFROST,
  rpcUrls: TESTNET_CHAIN_RPC_URLS_BIFROST,
  explorerUrl: TESTNET_CHAIN_EXPLORER_URL_BIFROST,
  name: TESTNET_CHAIN_NAME_BIFROST,
  blockTerm: DEFAULT_BLOCK_TERM,
  gasFee: 1,
  coin: TESTNET_TOKEN_BFC,
  order: 0,
};
export const TESTNET_CHAIN_ETHEREUM: Chain = {
  apiId: TESTNET_CHAIN_API_ID_ETHEREUM,
  id: TESTNET_CHAIN_ID_ETHEREUM,
  rpcUrls: TESTNET_CHAIN_RPC_URLS_ETHEREUM,
  explorerUrl: TESTNET_CHAIN_EXPLORER_URL_ETHEREUM,
  name: TESTNET_CHAIN_NAME_ETHEREUM,
  blockTerm: DEFAULT_BLOCK_TERM,
  gasFee: 0.001,
  coin: TESTNET_TOKEN_ETH,
  order: 1,
};
export const TESTNET_CHAIN_BASE_SEPOLIA: Chain = {
  apiId: TESTNET_CHAIN_API_ID_BASE_SEPOLIA,
  id: TESTNET_CHAIN_ID_BASE_SEPOLIA,
  rpcUrls: TESTNET_CHAIN_RPC_URLS_BASE_SEPOLIA,
  explorerUrl: TESTNET_CHAIN_EXPLORER_URL_BASE_SEPOLIA,
  name: TESTNET_CHAIN_NAME_BASE_SEPOLIA,
  blockTerm: DEFAULT_BLOCK_TERM,
  gasFee: 0.001,
  coin: TESTNET_TOKEN_ETH,
  order: 2,
};
export const TESTNET_CHAIN_ARBITRUM_SEPOLIA: Chain = {
  apiId: TESTNET_CHAIN_API_ID_ARBITRUM_SEPOLIA,
  id: TESTNET_CHAIN_ID_ARBITRUM_SEPOLIA,
  rpcUrls: TESTNET_CHAIN_RPC_URLS_ARBITRUM_SEPOLIA,
  explorerUrl: TESTNET_CHAIN_EXPLORER_URL_ARBITRUM_SEPOLIA,
  name: TESTNET_CHAIN_NAME_ARBITRUM_SEPOLIA,
  blockTerm: DEFAULT_BLOCK_TERM,
  gasFee: 0.001,
  coin: TESTNET_TOKEN_ETH,
  order: 2,
};
export const TESTNET_CHAIN_BSC: Chain = {
  apiId: TESTNET_CHAIN_API_ID_BSC,
  id: TESTNET_CHAIN_ID_BSC,
  rpcUrls: TESTNET_CHAIN_RPC_URLS_BSC,
  explorerUrl: TESTNET_CHAIN_EXPLORER_URL_BSC,
  name: TESTNET_CHAIN_NAME_BSC,
  blockTerm: DEFAULT_BLOCK_TERM,
  gasFee: 0.01,
  coin: TESTNET_TOKEN_BNB,
  order: 4,
};
export const TESTNET_CHAIN_POLYGON: Chain = {
  apiId: TESTNET_CHAIN_API_ID_POLYGON,
  id: TESTNET_CHAIN_ID_POLYGON,
  rpcUrls: TESTNET_CHAIN_RPC_URLS_POLYGON,
  explorerUrl: TESTNET_CHAIN_EXPLORER_URL_POLYGON,
  name: TESTNET_CHAIN_NAME_POLYGON,
  blockTerm: DEFAULT_BLOCK_TERM,
  gasFee: 0.1,
  coin: TESTNET_TOKEN_MATIC,
  order: 5,
};
export const TESTNET_CHAIN_BASE: Chain = {
  apiId: TESTNET_CHAIN_API_ID_BASE,
  id: TESTNET_CHAIN_ID_BASE,
  rpcUrls: TESTNET_CHAIN_RPC_URLS_BASE,
  explorerUrl: TESTNET_CHAIN_EXPLORER_URL_BASE,
  name: TESTNET_CHAIN_NAME_BASE,
  blockTerm: DEFAULT_BLOCK_TERM,
  gasFee: 0.001,
  coin: TESTNET_TOKEN_ETH,
  order: 3,
};
export const TESTNET_CHAIN_ARBITRUM: Chain = {
  apiId: TESTNET_CHAIN_API_ID_ARBITRUM,
  id: TESTNET_CHAIN_ID_ARBITRUM,
  rpcUrls: TESTNET_CHAIN_RPC_URLS_ARBITRUM,
  explorerUrl: TESTNET_CHAIN_EXPLORER_URL_ARBITRUM,
  name: TESTNET_CHAIN_NAME_ARBITRUM,
  blockTerm: DEFAULT_BLOCK_TERM,
  gasFee: 0.001,
  coin: TESTNET_TOKEN_ETH,
  order: 2,
};

// chains
const TESTNET_CHAINS = [
  TESTNET_CHAIN_BIFROST,
  TESTNET_CHAIN_ETHEREUM,
  TESTNET_CHAIN_BASE_SEPOLIA,
  TESTNET_CHAIN_ARBITRUM_SEPOLIA,
  TESTNET_CHAIN_BSC,
  TESTNET_CHAIN_POLYGON,
  TESTNET_CHAIN_BASE,
  TESTNET_CHAIN_ARBITRUM,
];

export default TESTNET_CHAINS;
