import * as Sentry from '@sentry/nextjs';
import axios from 'axios';
import {
  URL_ORIGIN_EVERDEX,
  URL_ORIGIN_BIHOLDER,
  URL_ORIGIN_CHAINRUNNER,
} from 'configs/urlOrigin';

const AXIOS_INSTANCE_EVERDEX = axios.create({
  baseURL: URL_ORIGIN_EVERDEX,
  withCredentials: false,
  timeout: 10000,
});
const AXIOS_INSTANCE_CHAINRUNNER = axios.create({
  baseURL: URL_ORIGIN_CHAINRUNNER,
  withCredentials: false,
  timeout: 30000,
});
const AXIOS_INSTANCE_BIHOLDER = axios.create({
  baseURL: URL_ORIGIN_BIHOLDER,
  withCredentials: false,
  timeout: 10000,
});

AXIOS_INSTANCE_EVERDEX.interceptors.response.use(
  (response) => response,
  (error) => {
    Sentry.captureException(error);

    return Promise.reject(error);
  }
);
AXIOS_INSTANCE_CHAINRUNNER.interceptors.response.use(
  (response) => response,
  (error) => {
    Sentry.captureException(error);

    return Promise.reject(error);
  }
);
AXIOS_INSTANCE_BIHOLDER.interceptors.response.use(
  (response) => response,
  (error) => {
    Sentry.captureException(error);

    return Promise.reject(error);
  }
);

export const everdex = AXIOS_INSTANCE_EVERDEX;
export const chainrunner = AXIOS_INSTANCE_CHAINRUNNER;
export const biholder = AXIOS_INSTANCE_BIHOLDER;
