var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1ffbe504d4c86e697cc6e248f4eeaa13017b1302"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN: string =
  process.env.SENTRY_DSN ?? process.env.NEXT_PUBLIC_SENTRY_DSN ?? '';

const RAW_IGNORE_ERRORS: string = process.env.NEXT_PUBLIC_IGNORE_ERRORS ?? '';

let ignoreErrors: string[] = [];

try {
  ignoreErrors = (JSON.parse(RAW_IGNORE_ERRORS) as string[]).filter(
    (ignoreError) => typeof ignoreError === 'string'
  );
} catch (error) {
  ignoreErrors = [];
}

Sentry.init({
  dsn: SENTRY_DSN,
  environment: process.env.NODE_ENV,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.0,
  integrations(integrations) {
    // integrations will be all default integrations
    return integrations.filter(function (integration) {
      return integration.name !== 'Dedupe';
    });
  },
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  ignoreErrors,
});
